import { Provider } from "react-redux";

import { renderAll } from "Helpers/ReactHelpers";
import { StationList } from "Stations/StationList";

import { buildStore } from "./Store";

const elements = document.querySelectorAll("div[data-react=StationList]");

if (elements.length > 0) {
	const { ActionProvider, actions, store } = buildStore();

	renderAll(elements, () => (
		<Provider store={store}>
			<ActionProvider value={actions}>
				<StationList />
			</ActionProvider>
		</Provider>
	));
}
