﻿import { useEffect, useRef } from "react";

import { search } from "Search/SearchStore";

import { useActions, useStateSelector } from "../Store";

const searchResultCount = 20;

export function StationSearchBox() {
	const inputRef = useRef<HTMLInputElement>(null);
	const [dispatch, actions] = useActions();
	const query = useStateSelector(state => state.search.query);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [inputRef]);

	return (
		<form>
			<div className="input-group">
				<input className="form-control form-control-lg" type="text" id="search" name="q"
					placeholder="Search by name, country, category, or URL"
					defaultValue={query}
					onChange={e => void dispatch(search(actions, e.currentTarget.value, searchResultCount))}
					ref={inputRef}
				/>
				<button className="btn btn-lg btn-outline-secondary">
					<i className="bi bi-search"></i>
					<span className="d-none">Search</span>
				</button>
			</div>
		</form>
	);
}
