import { shallowEqual } from "react-redux";

import { IStation } from "Api/V1/Models";
import { Badge } from "Components/Badge";
import { PlayButton, StarButton } from "Components/Buttons";
import { friendlyDate } from "Helpers/DateHelpers";
import { editStationUrl, stationsUrl } from "Models/Urls";

import { useStateSelector } from "../Store";

import { StationSearchBox } from "./StationSearchBox";

function getLastFailedEndpoint(station: IStation) {
	const failedEndpoints = station.endpoints.filter(s => s.failed != null && s.failed > 0).map(s => s.lastFailed);
	failedEndpoints.sort();

	return failedEndpoints[failedEndpoints.length - 1];
}

function getLastSuccessfulEndpoint(station: IStation) {
	const successfulEndpoints = station.endpoints.filter(s => s.successful != null && s.successful > 0).map(s => s.lastSuccessful);
	successfulEndpoints.sort();

	return successfulEndpoints[successfulEndpoints.length - 1];
}

export function StationList() {
	const appState = useStateSelector(state => ({
		canManageStations: state.stations.canManageStations,
		matchedStations: state.search.stations,
		matchedTags: state.search.tags,
		nextPage: state.stations.nextPage,
		page: state.stations.page,
		pageCount: state.stations.pageCount,
		prevPage: state.stations.prevPage,
		searchFailed: state.search.searchFailed,
		searching: state.search.searching,
		showSearch: state.search.showSearch,
		showSearchResults: state.search.showSearchResults,
		stations: state.stations.stations,
		tagName: state.stations.tagName
	}), shallowEqual);

	function renderSearchResults() {
		const showStations = !appState.searching && !appState.searchFailed;

		return (
			<div style={{ opacity: appState.searching ? 0.5 : 1 }}>
				{renderTags(appState.matchedTags, "btn btn-link")}
				{appState.matchedTags.length > 0 ? <hr /> : ""}
				{appState.searching ? <p>Searching..</p> : null}
				{appState.searchFailed ? renderSearchFailure() : null}
				{showStations ? renderStations(appState.matchedStations) : null}
			</div>
		);
	}

	function renderSearchFailure() {
		return (
			<div className="alert alert-warning">
				<span className="bi bi-exclamation-triangle-fill me-2"></span>
				<span>Search failed, please try again later</span>
			</div>
		);
	}

	function renderStationList() {
		return (
			<div>
				{renderPager("pre")}
				{renderStations(appState.stations)}
				{renderPager("post")}
			</div>
		);
	}

	function renderStations(stations: IStation[]) {
		if (stations.length === 0) {
			return <p>No matching stations</p>;
		}

		return (
			<ul className="list-unstyled">
				{stations.map(renderStation)}
			</ul>
		);
	}

	function renderStation(station: IStation) {
		const lastFailed = getLastFailedEndpoint(station);
		const lastSuccessful = getLastSuccessfulEndpoint(station);

		return (
			<li key={station.id ?? ""} className={"station-list-row" + (station.deleted ? " deleted" : (station.active === false ? " inactive" : ""))}>
				<span style={{ paddingRight: "10px" }}>
					<a href={station.links.page} className="large-text station-link">{station.title}</a>
				</span>
				<br />
				{renderTags(station.tags, "light")}
				<div className="station-list-controls d-none d-lg-inline-flex align-items-center gap-2">
					{lastSuccessful != null && <Badge type="success" message={`${friendlyDate(lastSuccessful)}`} />}
					{lastFailed != null && <Badge type="danger" message={`${friendlyDate(lastFailed)}`} />}
					<span className="btn-group">
						{appState.canManageStations && <a className="btn btn-secondary" href={editStationUrl(station) }>Edit</a>}
						<StarButton station={station} />
						<PlayButton station={station} />
					</span>
				</div>
			</li>
		);
	}

	function renderTags(tags: string[], className: string) {
		return (
			<ul className="list-inline">
				{tags.map(tag => (
					<li className="list-inline-item" key={tag}>
						<a href={stationsUrl(tag)} className={"tag " + className}>
							<i className="bi bi-tag-fill me-2 light"></i>{tag}
						</a>
					</li>
				))}
			</ul>
		);
	}

	function renderPager(mode: string) {
		const page = appState.page;
		const pageCount = appState.pageCount;

		if (!page || pageCount <= 1) {
			return <div></div>;
		}

		return (
			<div>
				{mode === "pre" ? "" : <hr />}
				<ul className="pagination justify-content-between">
					<li className={"page-item previous" + (page > 1 ? "" : " disabled")}>
						<a className="page-link rounded-circle" href={appState.prevPage}>
							<i className="bi bi-chevron-left"></i>
							<span className="d-none">Previous page</span>
						</a>
					</li>
					<li className="page-item disabled">
						<span className="page-link border-0">{`Page ${page} of ${pageCount}`}</span>
					</li>
					<li className={"page-item next" + (page < pageCount ? "" : " disabled")}>
						<a className="page-link rounded-circle" href={appState.nextPage}>
							<i className="bi bi-chevron-right"></i>
							<span className="d-none">Next page</span>
						</a>
					</li>
				</ul>
				{mode === "post" ? "" : <hr />}
			</div>
		);
	}

	return (
		<div>
			{appState.showSearch ? <StationSearchBox /> : ""}
			<hr />
			{appState.showSearchResults ? renderSearchResults() : renderStationList()}
		</div>
	);
}
